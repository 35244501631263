import {PathCfg} from "@antv/g2/src/geometry/path";
import {Annotation} from "@antv/g2plot/lib/types/annotation";
import {translationsObject} from "../locale";
import {locales} from "../entity/Entity";
import {TextOption} from "@antv/g2plot";

/**
 * The colours for line of graphs.
 */
export const colorLineActual = `#ea161d`;
export const colorLineENTSOEForecast = `#9eec0d`;
export const colorLine24InsightForecast = `#4d5dd9`;
export const colorLineENTSOEError = `#ea161d`;
export const colorLine24InsightError = `#3877de`;

export const graphHeight = 1000;
/**
 * 6 days
 */
export const pointsOfPartData = 576

export const entsoeActualTheme: Partial<PathCfg> = {
    theme: {
        defaultColor: colorLineActual,
        geometries: {
            line: {
                line: {
                    default: {
                        style: {
                            stroke: colorLineActual
                        }
                    }
                }
            }
        }
    }
};

export const entsoeForecastTheme: Partial<PathCfg> = {
    theme: {
        defaultColor: colorLineENTSOEForecast,
        geometries: {
            line: {
                line: {
                    default: {
                        style: {
                            stroke: colorLineENTSOEForecast
                        }
                    }
                }
            }
        }
    }
};

export const insight24ForecastTheme: Partial<PathCfg> = {
    theme: {
        defaultColor: colorLine24InsightForecast,
        geometries: {
            line: {
                line: {
                    default: {
                        style: {
                            stroke: colorLine24InsightForecast
                        }
                    }
                }
            }
        }
    }
};

export const entsoeErrorTheme: Partial<PathCfg> = {
    theme: {
        defaultColor: colorLineENTSOEError,
        geometries: {
            line: {
                line: {
                    default: {
                        style: {
                            stroke: colorLineENTSOEError
                        }
                    }
                }
            }
        }
    }
};

export const insight24ErrorTheme: Partial<PathCfg> = {
    theme: {
        defaultColor: colorLine24InsightError,
        geometries: {
            line: {
                line: {
                    default: {
                        style: {
                            stroke: colorLine24InsightError
                        }
                    }
                }
            }
        }
    }
};

export const graph1Region = {
    region: {
        start: {
            x: 0,
            y: 0
        },
        end: {
            x: 1,
            y: 0.40
        }
    },
    padding: [20, 10, 60, 70]
};

export const graph2Region = {
    region: {
        start: {
            x: 0,
            y: 0.40
        },
        end: {
            x: 1,
            y: 0.55
        }
    },
    padding: [20, 10, 20, 70]
};

export const graph3Region = {
    region: {
        start: {
            x: 0,
            y: 0.55
        },
        end: {
            x: 1,
            y: 0.85
        }
    },
    padding: [100, 10, 0, 70]
};

export const annotationForLiveDemoRegion = (latestActualDate: string): Annotation => {
    return {
        type: "region",
        top: false,
        start: [latestActualDate, "max"],
        end: ['max', "min"],
        style: {
            opacity: 20,
            fill: 'rgb(252,4,4)'
        }
    };
};

export const annotationForLiveDemoText = (latestActualDate: string,
                                          locale: typeof locales[number],
                                          isPartDayData: boolean): TextOption => {
    return {
        type: "text",
        top: true, // It's like zIndex. Top of all line in graph.
        offsetY: isPartDayData ? 34 : 20,
        position: [latestActualDate, "max"],
        content: `${translationsObject[locale].forecast_range_title[isPartDayData ? 1 : 0]}`,
        style: {
            textAlign: "left",
            textBaseline: "bottom",
            fontSize: isPartDayData ? 32 : 18,
            fill: 'rgb(3,3,3)'
        }
    };
};