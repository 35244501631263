import {TransferSystemOperation} from "../entity/Entity";
import {ROOT_URL} from "./ApiUtil";
import axios, {AxiosResponse} from "axios";

export const getTSOs = async (): Promise<TransferSystemOperation[]> => {
    const url: string = `${ROOT_URL}/tsos`;

    const axiosResponse: AxiosResponse = await axios.get(url);

    return axiosResponse.data.data;
}
