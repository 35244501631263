import {EnergyData, MapeData, YearErrors} from "../entity/Entity";
import {ROOT_URL} from "./ApiUtil";
import axios, {AxiosResponse} from "axios";

export const getDataForGraph = async (tsoId: number): Promise<EnergyData[]> => {
    const url: string = `${ROOT_URL}/energy-data/tso/${tsoId}`;

    const axiosResponse: AxiosResponse = await axios.get(url);

    return axiosResponse.data.data;
}

export const getYearsErrors = async (tsoId: number, yearsBefore: number): Promise<YearErrors[]> => {
    const url: string = `${ROOT_URL}/energy-data/tso/${tsoId}/errors/${yearsBefore}`;

    const axiosResponse: AxiosResponse = await axios.get(url);

    return axiosResponse.data;
}

export const fetchMapeData = async (tsoId: number): Promise<MapeData[]> => {
    const url: string = `${ROOT_URL}/energy-data/tso/${tsoId}/errors`;

    const axiosResponse: AxiosResponse = await axios.get(url);

    return axiosResponse.data;
};