import {Table} from "antd";
import React, {useEffect, useState} from "react";
import {locales, MapeData, TransferSystemOperation} from "../../entity/Entity";
import {getMapeData} from "../../service/DataService";
import {translationsObject} from "../../locale";
import {PanelRender} from "rc-table/lib/interface";

interface IProps {
    selectedTso: TransferSystemOperation;
    locale: typeof locales[number];
    onLoadTable: (showSpin: boolean) => void;
}

const MapeTable = (props: IProps): JSX.Element | null => {
    const [originDataSource, setOriginDataSource] = useState<MapeData[]>([]);
    const [dataSource, setDataSource] = useState<MapeData[]>([]);

    const getTitle = (): PanelRender<Object> => {
        return () => (
            <div style={{
                width: "100%",
                textAlign: "center",
                content: '#2a2929',
                fontSize: 24
            }}>
                {translationsObject[props.locale].mape_table_title}
            </div>
        );
    }

    const columns: any = [
        {
            title: '',
            dataIndex: 'rowName',
            key: 'name'
        },
        {
            align: 'center',
            title: `${translationsObject[props.locale].mape_last_week}`,
            dataIndex: 'lastWeek',
            key: 'lastWeek'
        },
        {
            title: `${translationsObject[props.locale].mape_last_month}`,
            dataIndex: 'lastMonth',
            align: 'center',
            key: 'lastMonth'
        },
        {
            title: `${(new Date()).getFullYear() - 1}`,
            dataIndex: 'year1',
            align: 'center',
            key: 'year1'
        },
        {
            title: `${(new Date()).getFullYear() - 2}`,
            dataIndex: 'year2',
            align: 'center',
            key: 'year2'
        },
        {
            title: `${(new Date()).getFullYear() - 3}`,
            dataIndex: 'year3',
            align: 'center',
            key: 'year3'
        },
    ];

    useEffect(() => {
        if (!!props.selectedTso) {
            getMapeData(props.selectedTso, setOriginDataSource);
        }
        // eslint-disable-next-line
    }, [props.selectedTso]);

    useEffect(() => {
        props.onLoadTable(!originDataSource.length);
        // eslint-disable-next-line
    }, [originDataSource]);

    useEffect(() => {
        const newDataSource: MapeData[] = originDataSource.map((data, index) => {
            return  {...data,
                rowName: translationsObject[props.locale].mape_table_row[index],
                lastMonth: `${data.lastMonth} %`,
                lastWeek: `${data.lastWeek} %`,
                year1: `${data.year1} %`,
                year2: `${data.year2} %`,
                year3: `${data.year3} %`
            };
            // newData.rowName = translationsObject[props.locale].mape_table_row[index];
            // newDataSource.push(newData);
        });

        setDataSource(newDataSource);

    }, [props.locale, originDataSource]);

    return !!originDataSource.length ?
        <Table
            title={getTitle()}
            pagination={false}
            columns={columns}
            dataSource={dataSource}
        />
        : null;
};

export default MapeTable;