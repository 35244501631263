import React from 'react';
import './App.css';
import InsightGraph from "./components/antv/InsightGraph";

function App() {
    return (
        <InsightGraph/>
    );
}

export default App;
