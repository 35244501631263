import {Country, Holiday} from "../entity/Entity";
import {Annotation} from "@antv/g2plot/lib/types/annotation";
import {getHolidayByCountry} from "../api/HolidayApi";

export const getHolidays = (
    country: Country,
    onSuccess?: (holidayGraphData: Annotation[]) => void
): void => {
    getHolidayByCountry(country)
        .then((holidays: Holiday[]) => {
            if (onSuccess) {
                const holidaysForGraph: Annotation[] = prepareHolidayForGraph(holidays);
                onSuccess(holidaysForGraph);
            }

        })
        .catch(() => {
            console.error("Can't loaded holidays data");
        })
}

const prepareHolidayForGraph = (holidays: Holiday[]): Annotation[] => {
        return holidays.map((holiday): Annotation => {
            return {
                type: "region",
                top: false,
                start: [holiday.dateStart, "max"],
                end: [holiday.dateEnd, "min"]
            };
        });
    }
;